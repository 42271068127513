import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import FormContainer from "../../../components/FormContainer";
import { listBlogInfo, updateBlog, removeBlog } from "../../../actions/blogActions";
import { BLOG_UPDATE_RESET } from "../../../constants/blogConstants";

import './BlogCreateScreen.scss'

const BlogCreateScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const blogId = id;

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [authorbio, setAuthorBio] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image2, setImage2] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const blogDetails = useSelector((state) => state.blogDetails);
  const { loading, error, blog } = blogDetails;

  const blogUpdate = useSelector((state) => state.blogUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = blogUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: BLOG_UPDATE_RESET });
      navigate("/admin/blogs");
    } else {
      if (!blog.title || blog._id !== blogId) {
        dispatch(listBlogInfo(blogId));
      } else {
        setTitle(blog.title);
        setAuthor(blog.author);
        setAuthorBio(blog.authorbio);
        setImage(blog.image);
        setImage2(blog.image2);
        setCategory(blog.category);
        setDescription(blog.description);
      }
    }
  }, [dispatch, navigate, blogId, blog, successUpdate]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("https://bhawanicity.com/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateBlog({
        _id: blogId,
        title,
        author,
        authorbio,
        image,
        image2,
        category,
        description,
      })
    );
  };

  return (
    <>
      <Link to="/admin/blogs" className="btn-bg mt-3 ml-5" onClick={() => dispatch(removeBlog(id))}>
        Back
      </Link>
      <FormContainer>
        <h1 className="data-table-title">Create Blog</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="title">
              <Form.Label className='form-item'>Title</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="author">
              <Form.Label className='form-item'>Author</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="authorbio">
              <Form.Label className='form-item'>Author Bio</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Author Bio"
                value={authorbio}
                onChange={(e) => setAuthorBio(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label className='form-item'>Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image url"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.File
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            {/* <Form.Group controlId="image2">
              <Form.Label className='form-item'>Image 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image url"
                value={image2}
                onChange={(e) => setImage2(e.target.value)}
              ></Form.Control>
              <Form.File
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group> */}

            <Form.Group controlId="category">
              <Form.Label className='form-item'>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>



            <Form.Group controlId="description">
              <Form.Label className='form-item'>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>


            <div >
              <Button type="submit" className="cs_btn cs_style_1 mt-3">
                <span>Create</span>
              </Button>
            </div>

            {/* <Button type="submit" variant="primary">
              Create
            </Button> */}
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default BlogCreateScreen;
