import React, { useEffect } from 'react';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import Section from '../Section';
// import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import DoctorDetailsSection from '../Section/DoctorDetailsSection';
// import AppointmentSectionStyle2 from '../Section/AppointmentSection/AppointmentSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
import { useDispatch, useSelector } from "react-redux";
import { listDoctorInfo } from "../../actions/doctorActions";
import { useParams } from "react-router-dom";
import AppointmentForm from '../AppointmentForm'

export default function DoctorDetails() {
  pageTitle('Doctor Details');

  const dispatch = useDispatch();

  const doctorDetails = useSelector((state) => state.doctorDetails);
  const { loading, error, doctor } = doctorDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { id } = useParams();
  const doctorId = id;

  useEffect(() => {
    if (!doctor._id || doctor._id !== doctorId) {
      dispatch(listDoctorInfo(doctorId));
      // dispatch({ type: DOCTOR_CREATE_REVIEW_RESET });
    }
  }, [dispatch, doctorId]);

  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={190} bottomLg={150} bottomXl={110}>
        <DoctorDetailsSection
          bgUrl="/images/doctors/doctor_details_bg.svg"
          imgUrl={doctor.image}
          name={doctor.name}
          department={doctor.department}
          designation={doctor.designation}
          description={doctor.description}
          // social={[
          //   { icon: 'fa6-brands:facebook-f', href: '/about' },
          //   { icon: 'fa6-brands:linkedin-in', href: '/about' },
          //   { icon: 'fa6-brands:twitter', href: '/about' },
          // ]}
          contactInfo={[
            { iconUrl: '/images/icons/call.svg', title: `${doctor.phonenumber}` },
            {
              iconUrl: '/images/icons/envlope.svg',
              title: `${doctor.email}`,
            },
          ]}
          contactInfoHeading="Contact Info"
          // schedules={[
          //   { day: 'Monday', time: '09.00-12.00' },
          //   { day: 'Wednesday', time: '15.00-18.00' },
          //   { day: 'Friday', time: '09.00-12.00' },
          // ]}
          // scheduleHeading="Appointment Schedules"
          degrees={[
            {
              title: `${doctor.degree1}`,
              subTitle: `${doctor.degree1desc}`,
            },
            {
              title:
                `${doctor.degree2}`,
              subTitle: `${doctor.degree2desc}`,
            },
            {
              title: `${doctor.degree3}`,
              subTitle: `${doctor.degree3desc}`,
            },
          ]}
          degreesHeading="Degrees"
          experiences={[
            {
              title:
                `${doctor.experience}`,
            },
            // {
            //   title:
            //     'Expertise in the treatment of mood disorders, anxiety disorders, and psychotic disorders.',
            // },
            // {
            //   title: `Special interest in women's mental health and perinatal psychiatry.`,
            // },
            // {
            //   title:
            //     'Experience managing complex cases that involve both mental health and medical issues.',
            // },
          ]}
          experiencesHeading="Experiences"
        // awards={[
        //   { title: 'Fellow of the American Psychiatric Association (FAPA).' },
        //   {
        //     title:
        //       'Recognized for research contributions with grants from the National Institute of Mental Health (NIMH) and the American Foundation for Suicide Prevention.',
        //   },
        // ]}
        // awardHeading="Awards/Achievements"
        />
        <div style={{ paddingTop: 100, paddingRight: 200, paddingLeft: 200 }}>
          <AppointmentForm doctorname={doctor.name} />
        </div>
      </Section>
      {/* <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_2/appointment_img.png"
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
        />
      </Section> */}

      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section> */}
    </>
  );
}
