import React, { useState } from 'react';
import { createContact } from "../../actions/contactActions";
import { CONTACT_UPDATE_RESET } from "../../constants/contactConstants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";

export default function ContactForm() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const submitHandler = async (e) => {
    e.preventDefault();
    await dispatch(
      createContact({
        name,
        email,
        subject,
        message
      })
    );

    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <div className="row">
        <Form onSubmit={submitHandler}>
          <div className="col-lg-15">
            <Form.Label className="cs_input_label cs_heading_color">Name</Form.Label>
            <Form.Control
              type="text"
              className="cs_form_field"
              placeholder="Your Good Name"
              value={name}
              style={{ height: 50 }}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-15">
            <Form.Label className="cs_input_label cs_heading_color">Email</Form.Label>
            <Form.Control
              type="email"
              className="cs_form_field"
              placeholder="Email"
              value={email}
              style={{ height: 50 }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-15">
            <Form.Label className="cs_input_label cs_heading_color">Subject</Form.Label>
            <Form.Control
              type="text"
              className="cs_form_field"
              placeholder="Your subject"
              value={subject}
              style={{ height: 50 }}
              onChange={(e) => setSubject(e.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-15">
            <Form.Label className="cs_input_label cs_heading_color">Message</Form.Label>
            <Form.Control
              cols={30}
              rows={10}
              className="cs_form_field"
              placeholder="Write something..."
              defaultValue={''}
              value={message}
              style={{ height: 50 }}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-15">
            <div className="cs_height_18" />
            <Button type="submit" className="cs_btn cs_style_1">
              <span>Submit</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
