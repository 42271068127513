// import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import List from '../../List';
// import ListStyle3 from '../../List/ListStyle3';
import ListStyle2 from '../../List/ListStyle2';
import Spacing from '../../Spacing';
import './index.css'

export default function DoctorDetailsSection({
  bgUrl,
  imgUrl,
  name,
  department,
  designation,
  description,
  social,
  contactInfo,
  contactInfoHeading,
  degrees,
  degreesHeading,
  experiences,
  experiencesHeading,
  // awards,
  // awardHeading,
  // schedules,
  // scheduleHeading,
}) {

  // const imgSantosh = '../../../assets/images/santosh_kharel.jpg'

  return (
    <div className="cs_doctor_details">
      <div
        className="cs_doctor_details_bg cs_bg_filed"
        style={{
          backgroundImage: `url(${bgUrl})`,
        }}
      />
      <Spacing md="85" />
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="cs_single_doctor overflow-hidden cs_radius_20">
              <img src={imgUrl} alt="Doctor" style={{ width: '600px', height: '300px' }} />
              <h3 className="cs_white_color cs_accent_bg mb-0 text-center cs_semibold cs_fs_24">
                {department}
              </h3>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Spacing md="55" />
              <h2 className="cs_fs_48 mb-0 cs_semibold">{name}</h2>
              <Spacing md="12" />
              <h3 className="cs_semibold cs_fs_24 mb-0">{designation}</h3>
              <Spacing md="32" />
              <p className="mb-0 cs_heading_color">{description}</p>
            </div>
            <Spacing md="94" lg="60" />
            <ListStyle2
              heading={contactInfoHeading}
              iconUrl="/images/icons/schedule.svg"
              data={contactInfo}
            />

            {/* <Spacing md="66" lg="60" />
            <ListStyle3
              heading={scheduleHeading}
              iconUrl="/images/icons/schedule.svg"
              data={schedules}
            /> */}
          </div>
          <div className="col-lg-6 offset-lg-1">

            <div className="align-right col-lg-6">
              <h2 className="cs_list_title cs_medium cs_fs_24 mt-4">
                Managing Director
              </h2>
              <div className="cs_team cs_style_1 cs_type_2 text-center cs_radius_20 overflow-hidden"
              //  style={{ height: 450, width: 300, marginTop: -95, marginLeft: 400 }}
              >
                <div className="cs_member_img">
                  {/* <Link to={href} className="d-block"> */}
                  <Link to={`/message`} className="d-block">
                    <img src="/images/oo.jpg" alt="Santosh Kharel" />
                    {/* <img href={`./santosh_kharel.jpg`} alt="Santosh Kharel" style={{ width: 100, height: 100 }} /> */}
                  </Link>
                  <div className="cs_label cs_white_color cs_accent_bg">Managing Director</div>
                </div>
                <div className="cs_team_meta cs_white_bg">
                  <div>
                    <h4 className="cs_member_name cs_fs_32">
                      {/* <Link to={href}>{name}</Link> */}
                      <Link to={`/message/`}>Mr. Santosh Kharel</Link>
                    </h4>
                    {/* <p className="cs_member_designation cs_heading_color cs_medium">
                      {designation}
                    </p> */}
                    <p className="cs_member_description">Mr. Santosh Kharel is a visionary leader leading team of doctors.</p>
                    <Link to={`/message/`}>Read More</Link>
                  </div>
                  {/* <div> */}
                  {/* <div className="cs_social_links"> */}
                  {/* {social?.map((item, index) => (
              <Link to={item.href} key={index}>
                <Icon icon={item.icon} />
              </Link>
            ))} */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            {/* <div className="cs_social_links cs_accent_bg cs_radius_15">
              {social?.map((item, index) => (
                <Link to={item.href} key={index}>
                  <Icon icon={item.icon} />
                </Link>
              ))}
            </div> */}
            <Spacing md="200" xl="150" lg="80" />
            <Spacing md="35" lg="0" />
            <List
              heading={degreesHeading}
              iconUrl="/images/icons/graduation.svg"
              data={degrees}
            />
            <Spacing md="70" lg="50" />
            <List
              heading={experiencesHeading}
              iconUrl="/images/icons/experience.svg"
              data={experiences}
            />
            {/* <Spacing md="70" lg="50" />
            <List
              heading={awardHeading}
              iconUrl="/images/icons/award2.svg"
              data={awards}
            /> */}
          </div>
        </div>
      </div>
    </div >
  );
}
