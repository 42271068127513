import React, { useEffect, useRef, useState } from "react";
// import UserAction from "./UserAction";
import "./Blog.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  listBlogs,
  removeBlog,
  createBlog,
} from "../../../actions/blogActions";
import { BLOG_CREATE_RESET } from "../../../constants/blogConstants";
import { Link, useNavigate } from 'react-router-dom';
import { HiDotsHorizontal } from "react-icons/hi";
import { Button } from "react-bootstrap";
import {
  MdEditSquare,
  MdOutlineDelete
} from "react-icons/md";

const TABLE_HEADS = [
  "Title",
  "Author",
  "Actions",
];

const Blog = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const blogList = useSelector((state) => state.blogList);
  const { blogs } = blogList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const blogCreate = useSelector((state) => state.blogCreate);
  const {
    success: successCreate,
    blog: createdBlog,
  } = blogCreate;

  const blogDelete = useSelector((state) => state.blogDelete);
  const {
    success: successDelete,
  } = blogDelete;

  useEffect(() => {
    dispatch({ type: BLOG_CREATE_RESET });

    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }

    if (successCreate) {
      navigate(`/admin/blog/${createdBlog._id}/create`);
    } else {
      dispatch(listBlogs());
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    successDelete,
    successCreate,
    createdBlog,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(removeBlog(id));
    }
  };

  const createBlogHandler = () => {
    dispatch(createBlog());
  };

  // const [showDropdown, setShowDropdown] = useState(false);
  // const handleDropdown = () => {
  //   setShowDropdown(!showDropdown);
  // };

  // const dropdownRef = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowDropdown(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  // const userDelete = useSelector((state) => state.userDelete);
  // const { success: successDelete } = userDelete;

  // const deleteHandler = (id) => {
  //   if (window.confirm("Are you sure")) {
  //     dispatch(deleteUser(id));
  //   }
  // };

  return (
    <section className="content-area-table">
      <div className="data-table-info">
        <h4 className="data-table-title">Blogs</h4>
      </div>
      <div className="button-container">
        <button className="cs_btn cs_style_1" onClick={createBlogHandler}>
          <span>Add Blog</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
      <div className="data-table-diagram">
        <table>
          <thead>
            <tr>
              {TABLE_HEADS?.map((th, index) => (
                <th key={index}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {blogs?.map((blog) => (
              // return (
              <tr key={blog._id}>
                {/* <td>{blog._id}</td> */}
                <td>{blog.title}</td>
                <td>{blog.author}</td>
                <td className="dt-cell-action">
                  <Link to={`/admin/blog/${blog._id}/edit`}>
                    <MdEditSquare />
                  </Link>
                  <Link onClick={() => deleteHandler(blog._id)}>
                    <MdOutlineDelete />
                  </Link>
                  {/* <button
                    type="button"
                    className="action-dropdown-btn"
                    onClick={handleDropdown}
                  >
                    <HiDotsHorizontal size={18} />
                    {showDropdown && (
                      <div className="action-dropdown-menu" ref={dropdownRef}>
                        <ul className="dropdown-menu-list" style={{ listStyle: 'none' }}>
                          <li className="dropdown-menu-item">
                            <Link to={`/admin/blog/${blog._id}/edit`} className="dropdown-menu-link">
                              Edit
                            </Link>
                          </li>
                          <li className="dropdown-menu-item">
                            <Link className="dropdown-menu-link" onClick={() => deleteHandler(blog._id)}>
                              Delete
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </button> */}
                  {/* <UserAction id={blog._id} /> */}
                </td>
              </tr>
            )
              // }
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Blog;