import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
// import FormContainer from "../components/FormContainer";
import { loginUser } from "../actions/userActions";
import "./styles.css";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const LoginScreen = ({ location }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

  const emailRef = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = "/";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  const handleEmail = () => {
    setEmail(emailRef.current.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ marginTop: "30vh" }}>
      {error && <Message variant="danger">{error}</Message>}
      {loading ? <Loader /> : (
        <div className="container cs_mt_minus_110">
          <div className="row">
            <div className="col-lg-5 offset-lg-3" style={{ marginBottom: "1.6em" }}>
              {/* <Form onSubmit={submitHandler} style={{ marginTop: 20 }}>
              <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={handleEmail}
                  ref={emailRef}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <div className="input-group">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button type="button" onClick={togglePasswordVisibility}>
                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                  </button>
                </div>
              </Form.Group>

              <Button type="submit" className="cs_btn cs_style_1 mt-3" style={{ marginBottom: 50 }}>
                <span>Sign In</span>
              </Button>
            </Form> */}
              <Form onSubmit={submitHandler} style={{ marginTop: "2em" }}>
                <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
                  <h3 >Sign In</h3>
                  <div className="row">
                    <div className="col-lg-15">
                      <label className="cs_input_label cs_heading_color">Email</label>
                      <input
                        type="email"
                        className="cs_form_field"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmail}
                        ref={emailRef}
                        style={{ height: 50 }}
                      />
                      <div className="cs_height_42 cs_height_xl_25" />
                    </div>
                    <div className="col-lg-15">
                      <label className="cs_input_label cs_heading_color">Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="cs_form_field"
                        placeholder="Password"
                        value={password}
                        style={{ height: 50 }}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div style={{ display: 'flex' }}>
                        <button type="button" onClick={togglePasswordVisibility}>
                          {showPassword ? <MdCheckBox style={{ height: 40 }} /> : <MdCheckBoxOutlineBlank style={{ height: 40 }} />}
                        </button>
                        <label className="cs_input_label cs_heading_color m-3"> Show Password</label>
                      </div>
                      <div className="cs_height_42 cs_height_xl_25" />
                    </div>


                    <div className="col-lg-15">
                      <div />
                      <button type="submit" className="cs_btn cs_style_1">
                        <span>Login</span>
                        <i>
                          <img src="/images/icons/arrow_white.svg" alt="Icon" />
                          <img src="/images/icons/arrow_white.svg" alt="Icon" />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginScreen;
