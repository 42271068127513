import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Post from '../Post';
import Sidebar from '../Sidebar';
import AuthorWidget from '../Widget/AuthorWidget';
import CommentsWidget from '../Widget/CommentsWidget';
import ReplyWidget from '../Widget/ReplyWidget';
import { pageTitle } from '../../helpers/PageTitle';

import { useDispatch, useSelector } from "react-redux";
import { listBlogInfo } from "../../actions/blogActions";
import { useParams } from "react-router-dom";

export default function Branches() {
  pageTitle('Branches');

  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title="Branches" />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            {/* <div className="cs_blog_details_tags">
              {tags.map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))}
            </div> */}
            {/* <div className="cs_blog_details_date">
              {blog.author}
            </div> */}
          </div>
          {/* <div className="cs_social_links_wrap">
            <h2>Contact:</h2>
            <div className="cs_social_links">
              <Link to="https://www.facebook.com/bhawanicityhealthclinicpvtltdbtw">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="https://www.youtube.com/@santoshkharel3648">
                <Icon icon="fa-brands:youtube" />
              </Link>
            </div>
          </div> */}
        </div>
        <Spacing md="55" />
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src="../../images/a.jpg"
            alt="Santosh Kharel"
            className="cs_radius_20"
            style={{ width: 400, height: 400 }}
          />
        </div>
        <Spacing md="90" lg="50" /> */}
        <div className="row">
          <div className="col-lg-12">
            <div className="cs_blog_details">
              <p>
                <p>1. Bhawani City Health Clinic Tulsipur Branch</p>

                <p>2. Bhawani City Health Clinic Taulihawa, Kapilvastu Branch</p>

                <p>3. Bhawani City Health Clinic Ghorahi, Dang Branch</p>

                <p>4. Bhawani City Health Clinic Tamghas, Gulmi Branch</p>

                <p>5. Bhawani City Health Clinic (Ruchika Clinic) Bhudkichowk, Butwal</p>
              </p>

            </div>
            {/* <Spacing md="85" /> */}
            {/* <AuthorWidget
              // imgUrl="/images/blog/author.png"
              name={blog.author}
              description={blog.authorbio}
            /> */}
            {/* <Spacing md="110" /> */}
            {/* <CommentsWidget title="Comments" /> */}
            {/* <Spacing md="92" /> */}
            {/* <ReplyWidget title="Leave a Reply" /> */}
          </div>
          {/* <div className="col-lg-4">
            <Sidebar />
          </div> */}
        </div>
        {/* <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" />
        <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div> */}
      </div>
      <Spacing md="200" xl="150" lg="110" />
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section> */}
    </>
  );
}
