import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  doctorListReducer,
  doctorDetailsReducer,
  doctorDeleteReducer,
  doctorCreateReducer,
  doctorUpdateReducer,
  doctorTopRatedReducer,
} from "./reducers/doctorReducers";
import {
  blogListReducer,
  blogDetailsReducer,
  blogDeleteReducer,
  blogCreateReducer,
  blogUpdateReducer,
  blogTopRatedReducer,
} from "./reducers/blogReducers";
import {
  contactListReducer,
  contactDetailsReducer,
  contactDeleteReducer,
  contactCreateReducer,
  contactUpdateReducer,
  contactTopRatedReducer,
} from "./reducers/contactReducers";
import {
  appointmentListReducer,
  appointmentDetailsReducer,
  appointmentDeleteReducer,
  appointmentCreateReducer,
  appointmentUpdateReducer,
  appointmentTopRatedReducer,
} from "./reducers/appointmentReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from "./reducers/userReducers";

const reducer = combineReducers({
  doctorList: doctorListReducer,
  doctorDetails: doctorDetailsReducer,
  doctorDelete: doctorDeleteReducer,
  doctorCreate: doctorCreateReducer,
  doctorUpdate: doctorUpdateReducer,
  doctorTopRated: doctorTopRatedReducer,
  blogList: blogListReducer,
  blogDetails: blogDetailsReducer,
  blogDelete: blogDeleteReducer,
  blogCreate: blogCreateReducer,
  blogUpdate: blogUpdateReducer,
  blogTopRated: blogTopRatedReducer,
  contactList: contactListReducer,
  contactDetails: contactDetailsReducer,
  contactDelete: contactDeleteReducer,
  contactCreate: contactCreateReducer,
  contactUpdate: contactUpdateReducer,
  contactTopRated: contactTopRatedReducer,
  appointmentList: appointmentListReducer,
  appointmentDetails: appointmentDetailsReducer,
  appointmentDelete: appointmentDeleteReducer,
  appointmentCreate: appointmentCreateReducer,
  appointmentUpdate: appointmentUpdateReducer,
  appointmentTopRated: appointmentTopRatedReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
