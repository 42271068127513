export const CONTACT_LIST_REQUEST = "CONTACT_LIST_REQUEST";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAIL = "CONTACT_LIST_FAIL";

export const CONTACT_DETAILS_REQUEST = "CONTACT_DETAILS_REQUEST";
export const CONTACT_DETAILS_SUCCESS = "CONTACT_DETAILS_SUCCESS";
export const CONTACT_DETAILS_FAIL = "CONTACT_DETAILS_FAIL";

export const CONTACT_DELETE_REQUEST = "CONTACT_DELETE_REQUEST";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAIL = "CONTACT_DELETE_FAIL";

export const CONTACT_CREATE_REQUEST = "CONTACT_CREATE_REQUEST";
export const CONTACT_CREATE_SUCCESS = "CONTACT_CREATE_SUCCESS";
export const CONTACT_CREATE_FAIL = "CONTACT_CREATE_FAIL";
export const CONTACT_CREATE_RESET = "CONTACT_CREATE_RESET";

export const CONTACT_UPDATE_REQUEST = "CONTACT_UPDATE_REQUEST";
export const CONTACT_UPDATE_SUCCESS = "CONTACT_UPDATE_SUCCESS";
export const CONTACT_UPDATE_FAIL = "CONTACT_UPDATE_FAIL";
export const CONTACT_UPDATE_RESET = "CONTACT_UPDATE_RESET";

export const CONTACT_CREATE_REVIEW_REQUEST = "CONTACT_CREATE_REVIEW_REQUEST";
export const CONTACT_CREATE_REVIEW_SUCCESS = "CONTACT_CREATE_REVIEW_SUCCESS";
export const CONTACT_CREATE_REVIEW_FAIL = "CONTACT_CREATE_REVIEW_FAIL";
export const CONTACT_CREATE_REVIEW_RESET = "CONTACT_CREATE_REVIEW_RESET";

export const CONTACT_TOP_REQUEST = "CONTACT_TOP_REQUEST";
export const CONTACT_TOP_SUCCESS = "CONTACT_TOP_SUCCESS";
export const CONTACT_TOP_FAIL = "CONTACT_TOP_FAIL";
