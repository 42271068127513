import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import FormContainer from "../../../components/FormContainer";
import { listDoctorInfo, updateDoctor, removeDoctor } from "../../../actions/doctorActions";
import { DOCTOR_UPDATE_RESET } from "../../../constants/doctorConstants";

import './DoctorCreateScreen.scss'

const DoctorCreateScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const doctorId = id;

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [experience, setExperience] = useState("");
  const [email, setEmail] = useState("");
  const [degree1, setDegree1] = useState("");
  const [degree1desc, setDegree1desc] = useState("");
  const [degree2, setDegree2] = useState("");
  const [degree2desc, setDegree2desc] = useState("");
  const [degree3, setDegree3] = useState("");
  const [degree3desc, setDegree3desc] = useState("");
  const [phonenumber, setPhonenumber] = useState();
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const doctorDetails = useSelector((state) => state.doctorDetails);
  const { loading, error, doctor } = doctorDetails;

  const doctorUpdate = useSelector((state) => state.doctorUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = doctorUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: DOCTOR_UPDATE_RESET });
      navigate("/admin/doctors");
    } else {
      if (!doctor.name || doctor._id !== doctorId) {
        dispatch(listDoctorInfo(doctorId));
      } else {
        setName(doctor.name);
        setImage(doctor.image);
        setDepartment(doctor.department)
        setDesignation(doctor.designation)
        setCategory(doctor.category);
        setDescription(doctor.description);
        setPhonenumber(doctor.phonenumber)
        setExperience(doctor.experience)
        setEmail(doctor.email)
        setDegree1(doctor.degree1)
        setDegree1desc(doctor.degree1desc)
        setDegree2(doctor.degree2)
        setDegree2desc(doctor.degree2desc)
        setDegree3(doctor.degree3)
        setDegree3desc(doctor.degree3desc)
      }
    }
  }, [dispatch, navigate, doctorId, doctor, successUpdate]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("https://bhawanicity.com/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateDoctor({
        _id: doctorId,
        name,
        image,
        department,
        designation,
        category,
        description,
        phonenumber,
        email,
        experience,
        degree1,
        degree1desc,
        degree2,
        degree2desc,
        degree3,
        degree3desc,
      })
    );
  };

  return (
    <>
      <Link to="/admin/doctors" className="btn-bg mt-3 ml-5" onClick={() => dispatch(removeDoctor(id))}>
        Back
      </Link>
      <FormContainer>
        <h1 className="data-table-title">Create Doctor</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label className='form-item'>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label className='form-item'>Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image url"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.File
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId="department">
              <Form.Label className='form-item'>Department</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="designation">
              <Form.Label className='form-item'>Designation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Designation"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label className='form-item'>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>



            <Form.Group controlId="description">
              <Form.Label className='form-item'>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="experience">
              <Form.Label className='form-item'>Experience</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <h4 className='form-item mt-4'>Contact Info:</h4>

            <Form.Group controlId="phonenumber">
              <Form.Label className='form-item'>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="experience">
              <Form.Label className='form-item'>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <h4 className='form-item mt-4'>Degree Info:</h4>

            <Form.Group controlId="degree1" className='mt-1'>
              <Form.Label className='form-item'>1.</Form.Label>
              <div className='form-items-flex'>
                <Form.Label className='form-item' style={{ marginRight: 8 }}>Title: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter degree"
                  value={degree1}
                  onChange={(e) => setDegree1(e.target.value)}
                ></Form.Control>
              </div>
              <div className='form-items-flex'>
                <Form.Label className='form-item' style={{ marginRight: 8 }}>Description: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter description"
                  value={degree1desc}
                  onChange={(e) => setDegree1desc(e.target.value)}
                ></Form.Control>
              </div>
            </Form.Group>

            <Form.Group controlId="degree2" className='mt-1'>
              <Form.Label className='form-item'>2.</Form.Label>
              <div className='form-items-flex'>
                <Form.Label className='form-item' style={{ marginRight: 8 }}>Title: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter degree2"
                  value={degree2}
                  onChange={(e) => setDegree2(e.target.value)}
                ></Form.Control>
              </div>
              <div className='form-items-flex'>
                <Form.Label className='form-item' style={{ marginRight: 8 }}>Description: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter description"
                  value={degree2desc}
                  onChange={(e) => setDegree2desc(e.target.value)}
                ></Form.Control>
              </div>
            </Form.Group>

            <Form.Group controlId="degree3" className='mt-1'>
              <Form.Label className='form-item'>3.</Form.Label>
              <div className='form-items-flex'>
                <Form.Label className='form-item' style={{ marginRight: 8 }}>Title: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter degree3"
                  value={degree3}
                  onChange={(e) => setDegree3(e.target.value)}
                ></Form.Control>
              </div>
              <div className='form-items-flex'>
                <Form.Label className='form-item' style={{ marginRight: 8 }}>Description: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter description"
                  value={degree3desc}
                  onChange={(e) => setDegree3desc(e.target.value)}
                ></Form.Control>
              </div>
            </Form.Group>

            <div >
              <Button type="submit" className="cs_btn cs_style_1 mt-3">
                <span>Create</span>
              </Button>
            </div>

            {/* <Button type="submit" variant="primary">
              Create
            </Button> */}
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default DoctorCreateScreen;
