import React, { useEffect } from "react";
// import UserAction from "./UserAction";
import "./Doctor.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  listDoctors,
  removeDoctor,
  createDoctor,
} from "../../../actions/doctorActions";
import { DOCTOR_CREATE_RESET } from "../../../constants/doctorConstants";
import { Link, useNavigate } from 'react-router-dom';
// import { HiDotsHorizontal } from "react-icons/hi";
// import { Button } from "react-bootstrap";
import {
  MdEditSquare,
  MdOutlineDelete
} from "react-icons/md";

const TABLE_HEADS = [
  "Name",
  "Department",
  "Designation",
  "Category",
  "Phone",
  "Actions",
];

const Doctor = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const doctorList = useSelector((state) => state.doctorList);
  const { doctors } = doctorList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const doctorCreate = useSelector((state) => state.doctorCreate);
  const {
    success: successCreate,
    doctor: createdDoctor,
  } = doctorCreate;

  const doctorDelete = useSelector((state) => state.doctorDelete);
  const {
    success: successDelete,
  } = doctorDelete;

  useEffect(() => {
    dispatch({ type: DOCTOR_CREATE_RESET });

    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }

    if (successCreate) {
      navigate(`/admin/doctor/${createdDoctor._id}/create`);
      // console.log("YOYOYO")
    } else {
      dispatch(listDoctors());
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    successDelete,
    successCreate,
    createdDoctor,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(removeDoctor(id));
    }
  };

  const createDoctorHandler = () => {
    dispatch(createDoctor());
  };

  // const [showDropdown, setShowDropdown] = useState(false);
  // const handleDropdown = () => {
  //   setShowDropdown(!showDropdown);
  // };

  // const dropdownRef = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowDropdown(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  // const userDelete = useSelector((state) => state.userDelete);
  // const { success: successDelete } = userDelete;

  // const deleteHandler = (id) => {
  //   if (window.confirm("Are you sure")) {
  //     dispatch(deleteUser(id));
  //   }
  // };

  return (
    <section className="content-area-table">
      <div className="data-table-info">
        <h4 className="data-table-title">Doctors</h4>
      </div>
      <div className="button-container">
        <button className="cs_btn cs_style_1" onClick={createDoctorHandler}>
          <span>Add Doctor</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
      {/* <Button type="submit" variant="primary" className='mt-2' onClick={createDoctorHandler}>
        Add Doctor
        <img src="/images/icons/arrow_white.svg" alt="Icon" />
      </Button> */}
      {/* <button type="button" className="my-6" onClick={createDoctorHandler}>Create Doctor</button> */}
      <div className="data-table-diagram">
        <table>
          <thead>
            <tr>
              {TABLE_HEADS?.map((th, index) => (
                <th key={index}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {doctors?.map((user) => (
              // return (
              <tr key={user._id}>
                {/* <td>{user._id}</td> */}
                <td>{user.name}</td>
                <td>{user.department}</td>
                <td>{user.designation}</td>
                <td>{user.category}</td>
                <td>{user.phonenumber}</td>
                <td className="dt-cell-action">
                  <Link to={`/admin/doctor/${user._id}/edit`}>
                    <MdEditSquare />
                  </Link>
                  <Link onClick={() => deleteHandler(user._id)}>
                    <MdOutlineDelete />
                  </Link>
                  {/* <button
                    type="button"
                    className="action-dropdown-btn"
                    onClick={handleDropdown}
                  >
                    <HiDotsHorizontal size={18} />
                    {showDropdown && (
                      <div className="action-dropdown-menu" ref={dropdownRef}>
                        <ul className="dropdown-menu-list" style={{ listStyle: 'none' }}>
                          <li className="dropdown-menu-item">
                            <Link to={`/admin/doctor/${user._id}/edit`} className="dropdown-menu-link">
                              Edit
                            </Link>
                          </li>
                          <li className="dropdown-menu-item">
                            <Link className="dropdown-menu-link" onClick={() => deleteHandler(user._id)}>
                              Delete
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </button> */}
                  {/* <UserAction id={user._id} /> */}
                </td>
              </tr>
            )
              // }
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Doctor;