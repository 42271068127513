import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
// import Layout2 from './components/Layout/Layout2';
// import Layout3 from './components/Layout/Layout3';
// import Layout4 from './components/Layout/Layout4';
import Home from './components/Pages/Home';
// import HomeStyle2 from './components/Pages/HomeStyle2';
// import HomeStyle3 from './components/Pages/HomeStyle3';
// import HomeStyle4 from './components/Pages/HomeStyle4';
import About from './components/Pages/About';
import Doctors from './components/Pages/Doctors';
// import Blogs from './components/Pages/Blogs';
import Blogs from './components/Pages/Blogs';
// import Appointments from './components/Pages/Appointments';
// import Departments from './components/Pages/Departments';
// import DepartmentDetails from './components/Pages/DepartmentDetails';
// import PricingPlan from './components/Pages/PricingPlan';
// import Gallery from './components/Pages/Gallery';
// import Timetable from './components/Pages/Timetable';
import BlogDetails from './components/Pages/BlogDetails';
import DoctorDetails from './components/Pages/DoctorDetails';
import Message from './components/Pages/Message';
import Branches from './components/Pages/Branches';
import Contact from './components/Pages/Contact';
import { useEffect } from 'react';
import ErrorPage from './components/Pages/ErrorPage';
// import Layout5 from './components/Layout/Layout5';
// import HomeStyle5 from './components/Pages/HomeStyle5';
// import HomeStyle6 from './components/Pages/HomeStyle6';

import LoginScreen from "./screens/LoginScreen";
// import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/Admin/Profile/ProfileScreen";
// import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/Admin/User/UserEditScreen";
import DoctorListScreen from "./screens/DoctorListScreen";
import DoctorCreateScreen from "./screens/Admin/Doctor/DoctorCreateScreen";
import DoctorEditScreen from "./screens/Admin/Doctor/DoctorEditScreen";
import BlogListScreen from "./screens/BlogListScreen";
// import ContactListScreen from "./screens/ContactListScreen";
import BlogCreateScreen from "./screens/Admin/Blog/BlogCreateScreen";
import BlogEditScreen from "./screens/Admin/Blog/BlogEditScreen";
import Dashboard from './screens/Admin/Dashboard'
import BaseLayout from "./layout/BaseLayout";
import Doctor from './screens/Admin/Doctor/Doctor';
import Blog from './screens/Admin/Blog/Blog';
import AdminContact from './screens/Admin/Contact/Contact';
import ContactViewed from './screens/Admin/Contact/ContactViewed';
import ContactViewScreen from './screens/Admin/Contact/ContactViewScreen';
import AdminAppointment from './screens/Admin/Appointment/Appointment.js';
import AppointmentViewed from './screens/Admin/Appointment/AppointmentViewed.js';
import AppointmentViewScreen from './screens/Admin/Appointment/AppointmentViewScreen.js';
// import OrderListScreen from "./screens/OrderListScreen";
// import { Container } from "react-bootstrap";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="doctors" element={<Doctors />} />
        {/* <Route path="doctors/:doctorId" element={<DoctorDetails />} /> */}
        <Route path="doctors/:id" element={<DoctorDetails />} />

        <Route path="blog" element={<Blogs />} />
        <Route path="blog/:id" element={<BlogDetails />} />
        <Route path="message" element={<Message />} />
        <Route path="branches" element={<Branches />} />

        {/* <Route path="appointments" element={<Appointments />} /> */}
        {/* <Route path="departments" element={<Departments />} />
        <Route
          path="departments/:departmentId"
          element={<DepartmentDetails />}
        /> */}
        {/* <Route path="pricing-plan" element={<PricingPlan />} /> */}
        {/* <Route path="gallery" element={<Gallery />} /> */}
        {/* <Route path="timetable" element={<Timetable />} /> */}
        <Route path="contact" element={<Contact />} />
        <Route path="/login" element={<LoginScreen />} />
      </Route>
      {/* <main className="py-3"> */}
      {/* <Route path="/" className="py-3"> */}
      {/* <Route path="/register" element={<RegisterScreen />} /> */}
      {/* <Route path="/profile" element={<ProfileScreen />} /> */}

      {/* <Route path="/admin/orderlist" element={<OrderListScreen />} /> */}
      {/* </Route> */}
      {/* </main> */}
      {/* <Route element={<Layout2 />}>
        <Route path="home-v2" element={<HomeStyle2 />} />
        <Route path="home-v6" element={<HomeStyle6 />} />
  </Route> */}
      {/* <Route path="home-v3" element={<Layout3 />}>
        <Route index element={<HomeStyle3 />} />
      </Route>
      <Route path="home-v4" element={<Layout4 />}>
        <Route index element={<HomeStyle4 />} />
      </Route>
      <Route path="home-v5" element={<Layout5 />}>
        <Route index element={<HomeStyle5 />} />
      </Route> */}
      <Route element={<BaseLayout />}>
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/profile" element={<ProfileScreen />} />
        <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
        {/* <Route path="/admin/userlist" element={<UserListScreen />} /> */}

        <Route path="/admin/doctors" element={<Doctor />} exact />
        <Route path="/admin/doctors/:pageNumber" element={<DoctorListScreen />} exact />
        <Route path="/admin/doctor/:id/create" element={<DoctorCreateScreen />} />
        <Route path="/admin/doctor/:id/edit" element={<DoctorEditScreen />} />
        {/* <Route path="/admin/doctors" element={<DoctorListScreen />} exact /> */}
        {/* <Route path="/admin/doctors" element={<DoctorListScreen />} exact /> */}

        <Route path="/admin/blogs" element={<Blog />} exact />
        <Route path="/admin/blogs/:pageNumber" element={<BlogListScreen />} exact />
        <Route path="/admin/blog/:id/create" element={<BlogCreateScreen />} />
        <Route path="/admin/blog/:id/edit" element={<BlogEditScreen />} />

        <Route path="/admin/contacts" element={<AdminContact />} exact />
        <Route path="/admin/contactsviewed" element={<ContactViewed />} exact />
        <Route path="/admin/contact/:id/view" element={<ContactViewScreen />} />
        <Route path="/admin/appointments" element={<AdminAppointment />} exact />
        <Route path="/admin/appointmentsviewed" element={<AppointmentViewed />} exact />
        <Route path="/admin/appointment/:id/view" element={<AppointmentViewScreen />} />
      </Route>
      {/* <Route path="/admin/dashboard" element={<Dashboard />} /> */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
