import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Post from '../Post';
import Sidebar from '../Sidebar';
import AuthorWidget from '../Widget/AuthorWidget';
import CommentsWidget from '../Widget/CommentsWidget';
import ReplyWidget from '../Widget/ReplyWidget';
import { pageTitle } from '../../helpers/PageTitle';

import { useDispatch, useSelector } from "react-redux";
import { listBlogInfo } from "../../actions/blogActions";
import { useParams } from "react-router-dom";

export default function BlogDetails() {
  pageTitle('Managing Director');

  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title="Message from Managing Director" />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            {/* <div className="cs_blog_details_tags">
              {tags.map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))}
            </div> */}
            {/* <div className="cs_blog_details_date">
              {blog.author}
            </div> */}
          </div>
          <div className="cs_social_links_wrap">
            <h2>Contact:</h2>
            <div className="cs_social_links">
              <Link to="https://www.facebook.com/bhawanicityhealthclinicpvtltdbtw">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              {/* <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link> */}
              <Link to="https://www.youtube.com/@santoshkharel3648">
                <Icon icon="fa-brands:youtube" />
              </Link>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src="../../images/a.jpg"
            alt="Santosh Kharel"
            className="cs_radius_20"
            style={{ width: 400, height: 400 }}
          />
        </div>
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-12">
            <div className="cs_blog_details">
              <p>
                <p>On behalf of Bhawani City Health Clinic, I am delighted to welcome you to our healthcare family. As the Managing Director, it is my pleasure to assure you that our team of dedicated professionals is committed to providing you with the highest quality of care and a compassionate experience.</p>

                <p>At Bhawani City Health Clinic, we prioritize your well-being above all else. Our mission is to offer comprehensive medical services tailored to meet your unique needs. Whether you are visiting for a routine check-up, a specialized treatment, or ongoing care, we are here to support you every step of the way.</p>

                <p>We believe in a holistic approach to health, integrating the latest medical advancements with a personalized touch. Our skilled practitioners are equipped with state-of-the-art facilities and are passionate about ensuring you receive the best possible care in a comfortable and welcoming environment.</p>

                <p>As we continue to grow and expand our services, we remain steadfast in our commitment to excellence. We value your trust in us and strive to exceed your expectations at every visit. Your feedback is invaluable in helping us enhance our services and improve your experience.</p>

                <p>Thank you for choosing Bhawani City Health Clinic. We look forward to building a lasting partnership in your journey to optimal health and wellness. If you have any questions or need assistance, please do not hesitate to reach out to our friendly staff.</p>

                <p>Warm regards,</p>

                <p>Santosh Kharel</p>
                <p>Managing Director</p>
                <p>Bhawani City Health Clinic</p>
                <p>+9779857032856</p>
                <p>Sarvan Path, Butwal, Rupandehi, Nepal</p>
              </p>

            </div>
            {/* <Spacing md="85" /> */}
            {/* <AuthorWidget
              // imgUrl="/images/blog/author.png"
              name={blog.author}
              description={blog.authorbio}
            /> */}
            {/* <Spacing md="110" /> */}
            {/* <CommentsWidget title="Comments" /> */}
            {/* <Spacing md="92" /> */}
            {/* <ReplyWidget title="Leave a Reply" /> */}
          </div>
          {/* <div className="col-lg-4">
            <Sidebar />
          </div> */}
        </div>
        {/* <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" />
        <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div> */}
      </div>
      <Spacing md="200" xl="150" lg="110" />
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section> */}
    </>
  );
}
