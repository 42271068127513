import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Post({
  title,
  author,
  image,
  _id
  // thumbUrl,
  // date,
  // btnText,
  // href,
  // socialShare,
  // variant,
}) {
  return (
    <div className={`cs_post cs_style_1`}>
      <Link to={`/blog/${_id}`} className="cs_post_thumb cs_view_mouse">
        <img src={image} alt={title} />
      </Link>
      <div className="cs_post_info">
        <div>
          <div className="cs_post_meta">
            <div className="cs_posted_by">{author}</div>
            {/* {socialShare && (
              <div className="cs_post_social">
                <Link to="/" className="cs_center rounded-circle">
                  <Icon icon="fa-brands:linkedin-in" />
                </Link>
                <Link to="/" className="cs_center rounded-circle">
                  <Icon icon="fa-brands:facebook-f" />
                </Link>
                <Link to="/" className="cs_center rounded-circle">
                  <Icon icon="fa-brands:twitter" />
                </Link>
              </div>
            )} */}
          </div>
          <h2 className="cs_post_title cs_semibold cs_fs_32">
            <Link to={`/blog/${_id}`}>{title}</Link>
          </h2>
        </div>
        {/* {btnText && ( */}
        <div className="cs_heading_color cs_medium">
          <Link to={`/blog/${_id}`} className="cs_post_btn">
            Learn More
          </Link>
        </div>
        {/* // )} */}
      </div>
    </div>
  );
}
