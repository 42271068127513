import React, { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import { listAppointmentInfo } from "../../../actions/appointmentActions";

const AppointmentViewScreen = () => {
  const { id } = useParams();
  const appointmentId = id;

  const dispatch = useDispatch();

  const appointmentDetails = useSelector((state) => state.appointmentDetails);
  const { loading, error, appointment } = appointmentDetails;

  useEffect(() => {
    dispatch(listAppointmentInfo(appointmentId));
  }, [dispatch, appointmentId]);

  return (
    <>
      <NavLink to="/admin/appointments" className="btn-bg mt-3 ml-5">
        Back
      </NavLink>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div style={{ marginBottom: '20px', marginTop: '40px' }}>
          <h4>Name:</h4>
          <p>{appointment.name}</p>
          <hr />

          <h4 style={{ marginTop: '10px' }}>Phone:</h4>
          <p>{appointment.phone}</p>
          <hr />

          <h4 style={{ marginTop: '10px' }}>Doctor:</h4>
          <p>{appointment.doctor}</p>
          <hr />

          <h4 style={{ marginTop: '10px' }}>Date:</h4>
          <p>{appointment.date.split('T')[0]}</p>
          <hr />

          <h4 style={{ marginTop: '10px' }}>Time:</h4>
          <p>{appointment.time}</p>
          <hr />

          <h4 style={{ marginTop: '10px' }}>Reason:</h4>
          <p>{appointment.reason}</p>
          <hr />
        </div>
      )}
    </>
  );
};

export default AppointmentViewScreen;
