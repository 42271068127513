import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Post from '../Post';
import Sidebar from '../Sidebar';
import AuthorWidget from '../Widget/AuthorWidget';
import CommentsWidget from '../Widget/CommentsWidget';
import ReplyWidget from '../Widget/ReplyWidget';
import { pageTitle } from '../../helpers/PageTitle';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { listBlogInfo } from "../../actions/blogActions";
import { useParams } from "react-router-dom";

export default function BlogDetails() {
  pageTitle('Blog Details');
  const dispatch = useDispatch();

  const blogDetails = useSelector((state) => state.blogDetails);
  const { loading, error, blog } = blogDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { id } = useParams();
  const blogId = id;

  useEffect(() => {
    if (!blog._id || blog._id !== blogId) {
      dispatch(listBlogInfo(blogId));
      // dispatch({ type: DOCTOR_CREATE_REVIEW_RESET });
    }
  }, [dispatch, blogId]);

  // Social media sharing URLs
  const shareUrl = window.location.href;
  const encodedShareUrl = encodeURIComponent(shareUrl);
  const encodedTitle = encodeURIComponent(blog.title);

  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.description} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.description} />
        <meta property="og:image" content={blog.image} />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="article" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.description} />
        <meta name="twitter:image" content={blog.image} />
        <meta name="twitter:url" content={shareUrl} />
      </Helmet>

      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={blog.title} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            {/* <div className="cs_blog_details_tags">
              {tags.map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))}
            </div> */}
            <div className="cs_blog_details_date">
              {blog.author}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>Share:</h2>
            <div className="cs_social_links">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareUrl}&t=${encodedTitle}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="fa-brands:facebook-f" />
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="fa-brands:linkedin-in" />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodedShareUrl}&text=${encodedTitle}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="fa-brands:twitter" />
              </a>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={blog.image}
          alt="Blog Details"
          className="cs_radius_20 d-flex m-auto"
        // style={{ width: 1620, height: 758 }}
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-12">
            <div className="cs_blog_details">
              <p>{blog.description}</p>
            </div>
            <Spacing md="85" />
            <AuthorWidget
              // imgUrl="/images/blog/author.png"
              name={blog.author}
              description={blog.authorbio}
            />
            {/* <Spacing md="110" /> */}
            {/* <CommentsWidget title="Comments" /> */}
            {/* <Spacing md="92" /> */}
            {/* <ReplyWidget title="Leave a Reply" /> */}
          </div>
          {/* <div className="col-lg-4">
            <Sidebar />
          </div> */}
        </div>
        {/* <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" />
        <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div> */}
      </div>
      <Spacing md="200" xl="150" lg="110" />
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section> */}
    </>
  );
}
