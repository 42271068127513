import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createAppointment } from "../../actions/appointmentActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";

export default function AppointmentForm(doctorname) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [doctor, setDoctor] = useState(doctorname.doctorname);
  const [time, setTime] = useState("");
  const [reason, setReason] = useState("");
  // const [selectedDate, setSelectedDate] = useState(null);

  const dispatch = useDispatch();

  const submitHandler = async (e) => {
    e.preventDefault();

    // setDoctor(`${doctorname.doctorname}`)

    // console.log("DOCT", doctor)

    await dispatch(
      createAppointment({
        name,
        phone,
        date,
        time,
        doctor,
        reason
      })
    );

    setName("");
    setPhone("");
    setDate("");
    setTime("");
    setReason("");
  };

  console.log("doctorname: ", doctorname)
  return (
    <Form onSubmit={submitHandler} className="row">
      <h2>Appointment</h2>
      <div className="col-lg-6">
        <Form.Label className="cs_input_label cs_heading_color">Name</Form.Label>
        <Form.Control
          type="text"
          className="cs_form_field"
          placeholder="Your Good Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <Form.Label className="cs_input_label cs_heading_color">Phone Number</Form.Label>
        <Form.Control
          type="text"
          className="cs_form_field"
          placeholder="Your Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Doctor
        </label>
        <p
          // type="text"
          className="cs_form_field text-black"
        // placeholder={name.name}
        >
          {doctorname.doctorname}
        </p>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <Form.Label className="cs_input_label cs_heading_color">
          Preferred Date
        </Form.Label>

        <div className="cs_with_icon_input">
          <i style={{ marginTop: -15, marginLeft: -45 }}>
            <Icon icon="fa6-solid:calendar-days" />
          </i>
          <DatePicker
            selected={date}
            onChange={date => setDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            isClearable
            placeholderText="dd/mm/yyyy"
            style={{ padding: 15 }}
          />

        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Preferred Time
        </label>
        <div className="cs_with_icon_input">
          <i style={{ marginTop: -15, marginLeft: -45 }}>
            <Icon icon="fa6-regular:clock" />
          </i>
          <Form.Control
            type="time"
            // className="cs_form_field cs_timepicker"
            placeholder="Enter Time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Reason for Visit
        </label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="routineCheckup"
              defaultValue="routineCheckup"
              onClick={(e) => setReason("routineCheckup")}
            />
            <label className="cs_radio_label" htmlFor="routineCheckup">
              Routine Checkup
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="newPatientVisit"
              defaultValue="newPatientVisit"
              defaultChecked=""
              onClick={(e) => setReason("newPatientVisit")}
            />
            <label className="cs_radio_label" htmlFor="newPatientVisit">
              New Patient Visit
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="specificConcern"
              defaultValue="specificConcern"
              onClick={(e) => setReason("specificConcern")}
            />
            <label className="cs_radio_label" htmlFor="specificConcern">
              Specific Concern
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      {/* <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Department</label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="pediatric"
              defaultValue="pediatric"
            />
            <label className="cs_radio_label" htmlFor="pediatric">
              Pediatric
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="obstetricsGynecology"
              defaultValue="obstetricsGynecology"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="obstetricsGynecology">
              Obstetrics and Gynecology
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="cardiology"
              defaultValue="cardiology"
            />
            <label className="cs_radio_label" htmlFor="cardiology">
              Cardiology
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="neurology"
              defaultValue="neurology"
            />
            <label className="cs_radio_label" htmlFor="neurology">
              Neurology
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div> */}
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
    </Form>
  );
}
