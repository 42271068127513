import User from './User/User.js'

const Dashboard = () => {
    return (
        <>
            <div className="content-area">
                <User />
            </div>
        </>
    );
};

export default Dashboard;
