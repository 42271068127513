export const BLOG_LIST_REQUEST = "BLOG_LIST_REQUEST";
export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const BLOG_LIST_FAIL = "BLOG_LIST_FAIL";

export const BLOG_DETAILS_REQUEST = "BLOG_DETAILS_REQUEST";
export const BLOG_DETAILS_SUCCESS = "BLOG_DETAILS_SUCCESS";
export const BLOG_DETAILS_FAIL = "BLOG_DETAILS_FAIL";

export const BLOG_DELETE_REQUEST = "BLOG_DELETE_REQUEST";
export const BLOG_DELETE_SUCCESS = "BLOG_DELETE_SUCCESS";
export const BLOG_DELETE_FAIL = "BLOG_DELETE_FAIL";

export const BLOG_CREATE_REQUEST = "BLOG_CREATE_REQUEST";
export const BLOG_CREATE_SUCCESS = "BLOG_CREATE_SUCCESS";
export const BLOG_CREATE_FAIL = "BLOG_CREATE_FAIL";
export const BLOG_CREATE_RESET = "BLOG_CREATE_RESET";

export const BLOG_UPDATE_REQUEST = "BLOG_UPDATE_REQUEST";
export const BLOG_UPDATE_SUCCESS = "BLOG_UPDATE_SUCCESS";
export const BLOG_UPDATE_FAIL = "BLOG_UPDATE_FAIL";
export const BLOG_UPDATE_RESET = "BLOG_UPDATE_RESET";

export const BLOG_CREATE_REVIEW_REQUEST = "BLOG_CREATE_REVIEW_REQUEST";
export const BLOG_CREATE_REVIEW_SUCCESS = "BLOG_CREATE_REVIEW_SUCCESS";
export const BLOG_CREATE_REVIEW_FAIL = "BLOG_CREATE_REVIEW_FAIL";
export const BLOG_CREATE_REVIEW_RESET = "BLOG_CREATE_REVIEW_RESET";

export const BLOG_TOP_REQUEST = "BLOG_TOP_REQUEST";
export const BLOG_TOP_SUCCESS = "BLOG_TOP_SUCCESS";
export const BLOG_TOP_FAIL = "BLOG_TOP_FAIL";
