export const DOCTOR_LIST_REQUEST = "DOCTOR_LIST_REQUEST";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";

export const DOCTOR_DETAILS_REQUEST = "DOCTOR_DETAILS_REQUEST";
export const DOCTOR_DETAILS_SUCCESS = "DOCTOR_DETAILS_SUCCESS";
export const DOCTOR_DETAILS_FAIL = "DOCTOR_DETAILS_FAIL";

export const DOCTOR_DELETE_REQUEST = "DOCTOR_DELETE_REQUEST";
export const DOCTOR_DELETE_SUCCESS = "DOCTOR_DELETE_SUCCESS";
export const DOCTOR_DELETE_FAIL = "DOCTOR_DELETE_FAIL";

export const DOCTOR_CREATE_REQUEST = "DOCTOR_CREATE_REQUEST";
export const DOCTOR_CREATE_SUCCESS = "DOCTOR_CREATE_SUCCESS";
export const DOCTOR_CREATE_FAIL = "DOCTOR_CREATE_FAIL";
export const DOCTOR_CREATE_RESET = "DOCTOR_CREATE_RESET";

export const DOCTOR_UPDATE_REQUEST = "DOCTOR_UPDATE_REQUEST";
export const DOCTOR_UPDATE_SUCCESS = "DOCTOR_UPDATE_SUCCESS";
export const DOCTOR_UPDATE_FAIL = "DOCTOR_UPDATE_FAIL";
export const DOCTOR_UPDATE_RESET = "DOCTOR_UPDATE_RESET";

export const DOCTOR_CREATE_REVIEW_REQUEST = "DOCTOR_CREATE_REVIEW_REQUEST";
export const DOCTOR_CREATE_REVIEW_SUCCESS = "DOCTOR_CREATE_REVIEW_SUCCESS";
export const DOCTOR_CREATE_REVIEW_FAIL = "DOCTOR_CREATE_REVIEW_FAIL";
export const DOCTOR_CREATE_REVIEW_RESET = "DOCTOR_CREATE_REVIEW_RESET";

export const DOCTOR_TOP_REQUEST = "DOCTOR_TOP_REQUEST";
export const DOCTOR_TOP_SUCCESS = "DOCTOR_TOP_SUCCESS";
export const DOCTOR_TOP_FAIL = "DOCTOR_TOP_FAIL";
